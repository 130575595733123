import {InternalApi} from './internal/internal-api'
import {Event, EventCallback} from './shared/internal/event'
import {EventEmitter, Listener} from './shared/internal/util/event-emitter'
import {UnbluApiError, UnbluErrorType} from './shared/unblu-api-error'
import {IndividualUiState} from './model/individualui_state'
import {GeneralEventType} from "./internal/module/general-module"
import {ActiveIndividualUiView} from "./model/individualui_component";

/**
 * Listener called whenever the UI state changes.
 * @param uistate The new UI state.
 */
export type UiStateChangeListener = (uistate: IndividualUiState) => void

/**
 * Listener called whenever the active individual UI view changes.
 *
 * NOTE: This listener is also triggered when the view in individual UI changes, but the UI isn't
 * visible, for example, because it's collapsed.
 *
 * @param uicomponent The new individual UI component.
 */
export type UiActiveIndividualUiViewChangeListener = (uicomponent: ActiveIndividualUiView) => void

/**
 * This class allows you to control the UI state and the Unblu individual UI.
 */
export class UnbluUiApi {

    private internalListeners: { [key: string]: EventCallback } = {}
    private eventEmitter = new EventEmitter()

    /**
     * Event emitted every time the state of the individual UI is changed.
     *
     * @event uiStateChange
     * @see {@link on} for listener registration
     * @see {@link UiStateChangeListener}
     */
    public static readonly UI_STATE_CHANGE: 'uiStateChange' = 'uiStateChange'

    /**
     * Event emitted every time individual UI view changes.
     *
     * NOTE: This event is also triggered when an individual UI view change happens, but the UI isn't
     * visible, for example, because it's collapsed.
     *
     * @event uiActiveIndividualUiViewChange
     * @see {@link on} for listener registration
     * @see {@link UiActiveIndividualUiViewChangeListener}
     */
    public static readonly UI_ACTIVE_INDIVIDUAL_UI_VIEW_CHANGE: 'uiActiveIndividualUiViewChange' = 'uiActiveIndividualUiViewChange';


    /**
     * @hidden
     */
    constructor(private internalApi: InternalApi) {
        internalApi.meta.on('upgraded', () => this.onUpgraded())
    }

    /**
     * Registers an event listener for the given event.
     * @param event The uistateChange event.
     * @param listener The listener to be called.
     * @see {@link UI_STATE_CHANGE}
     */
    public on(event: typeof UnbluUiApi.UI_STATE_CHANGE, listener: UiStateChangeListener): void

    /**
     * Registers an event listener for the given event.
     * @param event The uiOverviewOpen event.
     * @param listener The listener to be called.
     * @see {@link UI_ACTIVE_INDIVIDUAL_UI_VIEW_CHANGE}
     */
    public on(event: typeof UnbluUiApi.UI_ACTIVE_INDIVIDUAL_UI_VIEW_CHANGE, listener: UiActiveIndividualUiViewChangeListener): void

    public on(event: GeneralEventType, listener: Listener): void {
        const needsInternalSubscription = !this.eventEmitter.hasListeners(event)
        this.eventEmitter.on(event, listener)
        if (needsInternalSubscription)
            this.onInternal(event).catch(e => console.warn('Error registering internal listener for event:', event, 'error:' + e, e))
    }

    /**
     * Removes a previously registered listener
     * @param event The event to unregister from.
     * @param listener The listener to remove.
     */
    public off(event: GeneralEventType, listener: Listener): boolean {
        const removed = this.eventEmitter.off(event, listener)
        if (!this.eventEmitter.hasListeners(event))
            this.offInternal(event).catch(e => console.warn('Error removing internal listener for event:', event, 'error:' + e, e))
        return removed
    }


    private async onInternal(eventName: GeneralEventType) {
        let internalListener: EventCallback
        switch (eventName) {
            case UnbluUiApi.UI_STATE_CHANGE:
                internalListener = (event: Event<string>) => {
                    this.eventEmitter.emit(event.name, event.data)
                }
                break
            case UnbluUiApi.UI_ACTIVE_INDIVIDUAL_UI_VIEW_CHANGE:
                internalListener = (event: Event<string>) => {
                    this.eventEmitter.emit(event.name, event.data)
                }
                break
            default:
                throw new UnbluApiError(UnbluErrorType.INVALID_FUNCTION_ARGUMENTS, 'Registration to unknown event:' + eventName)
        }

        if (await this.internalApi.meta.isUpgraded()) {
            this.internalListeners[eventName] = internalListener
            try {
                await this.internalApi.general.on(eventName, internalListener)
            } catch (e) {
                delete this.internalListeners[eventName]
                throw e
            }
        }
    }

    private async offInternal(eventName: GeneralEventType) {
        const listener = this.internalListeners[eventName]
        if (listener == null) {
            return
        }
        delete this.internalListeners[eventName]
        await this.internalApi.general.off(eventName, listener)
    }


    /**
     * Opens the individual UI if it is collapsed and collapses it if it is open.
     */
    public async toggleIndividualUi(): Promise<void> {
        await this.requireUpgrade()
        await this.internalApi.general.toggleIndividualUi()
    }

    /**
     * Navigates the individual UI to the PIN entry UI.
     *
     * **NOTE:** calling this method will NOT automatically open the Unblu UI if it is collapsed. Use {@link openIndividualUi} if this is needed.
     */
    public async openPinEntryUi(): Promise<void> {
        await this.requireUpgrade()
        await this.internalApi.general.openPinEntryUi()
    }

    /**
     * Navigates the individual UI to the overview UI.
     *
     * <p>
     *     Be aware that this method will force to close any currently open conversation. Depending on the conversation's configuration and the activity in it a prompt may be displayed that has to be accepted by the visitor before the navigation to the overview can happen.
     * </p>
     *
     * **NOTE:** calling this method will NOT automatically open the Unblu UI if it is collapsed. Use {@link openIndividualUi} if this is needed.
     */
    public async  openOverviewUi(): Promise<void> {
        await this.requireUpgrade();
        await this.internalApi.general.openOverviewUi();
    }


    /**
     * Pop-out the individual UI into a separate window.
     *
     * **NOTE:** this has to be called in a click-event in order to be able to open the pop-up window without being blocked by the browser!
     */
    public async popoutIndividualUi(): Promise<void> {
        await this.requireUpgrade()
        await this.internalApi.general.popoutIndividualUi()
    }

    /**
     * Pop-in the individual UI when it is in [POPPED_OUT]{@link IndividualUiState.POPPED_OUT} state.
     *
     * The pop-out window will automatically close and the individual UI will be displayed in the original window again.
     */
    public async popinIndividualUi(): Promise<void> {
        await this.requireUpgrade()
        await this.internalApi.general.popinIndividualUi()
    }

    /**
     * Maximize the individual UI - Does nothing if it is already maximized or popped out.
     */
    public async maximizeIndividualUi(): Promise<void> {
        await this.requireUpgrade()
        await this.internalApi.general.maximizeIndividualUi()
    }

    /**
     * Minimize the individual UI - Does nothing if it is already minimized.
     */
    public async minimizeIndividualUi(): Promise<void> {
        await this.requireUpgrade()
        await this.internalApi.general.minimizeIndividualUi()
    }

    /**
     * Opens the individual UI if it was collapsed. - Does nothing if it was already open.
     */
    public async openIndividualUi(): Promise<void> {
        await this.requireUpgrade()
        await this.internalApi.general.openIndividualUi()
    }

    /**
     * Collapses the individual UI if it was open. - Does nothing if it was already collapsed.
     */
    public async collapseIndividualUi(): Promise<void> {
        await this.requireUpgrade()
        await this.internalApi.general.collapseIndividualUi()
    }

    /**
     * Get the state of the individual UI.
     * @return A promise that resolves to the {@link IndividualUiState} of the individual UI.
     */
    public async getIndividualUiState(): Promise<IndividualUiState> {
        if (!await this.internalApi.meta.isUpgraded()) {
            return IndividualUiState.COLLAPSED
        }
        return await this.internalApi.general.getIndividualUiState()
    }

    /**
     * Get the active individual UI view.
     *
     * NOTE: The view being active doesn't necessarily mean it's visible to the user. The UI as a whole may be
     * collapsed, for instance.
     *
     * @return A promise that resolves to the {@link ActiveIndividualUiView} of the individual UI.
     * @see {@link getIndividualUiState}
     */
    public async getActiveIndividualUiView(): Promise<ActiveIndividualUiView> {
        if (!await this.internalApi.meta.isUpgraded()) {
            return ActiveIndividualUiView.UNKNOWN
        }
        return await this.internalApi.general.getActiveIndividualUiView()
    }

    private async requireUpgrade(): Promise<void> {
        await this.internalApi.meta.upgrade(false)
    }

    private onUpgraded() {
        for (let event of this.eventEmitter.getEventsWithListeners()) {
            // register internal listeners for all events that need upgrade.
            if (!this.internalListeners[event])
                this.onInternal(event as GeneralEventType)
        }
    }
}
