export const enum ActiveIndividualUiView {
    /**
     * The individual UI is showing the overview.
     */
    OVERVIEW = 'OVERVIEW',
    /**
     * The individual UI is showing the pin entry screen.
     */
    PIN_ENTRY = 'PIN_ENTRY',
    /**
     * The individual UI is showing messages in a conversation.
     */
    CONVERSATION_MESSAGING = 'CONVERSATION_MESSAGING',
    /**
     * The individual UI is showing the shared media view.
     */
    CONVERSATION_SHARED_MEDIA = 'CONVERSATION_SHARED_MEDIA',
    /**
     * The individual UI is showing messages in a conversation.
     */
    CONVERSATION_CALL = 'CONVERSATION_CALL',
    /**
     * The Floating Visitor UI hasn't fully initialized or active view is unknown.
     *
     * NOTE: You shouldn't rely on this option for specific views. New views added to the Unblu individual UI will show
     * as {@link UNKNOWN} at first but may be added to the enum as a specific view at some point in the future.
     */
    UNKNOWN = 'UNKNOWN'
}
