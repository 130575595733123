import {ApiBridge} from '../api-bridge'
import {EventCallback} from '../event'

export class InternalModule<T extends string, E extends string> {
    constructor(private bridge: ApiBridge, public moduleName: string) {

    }

    protected callApiFunction(functionName: T, args: any[]): Promise<any> {
        return this.bridge.callApiFunction(this.moduleName, functionName, args)
    }

    public on(eventName: E, listener: EventCallback): Promise<void> {
        return this.bridge.on(this.moduleName, eventName, listener)
    }

    public off(eventName: E, listener: EventCallback): Promise<void> {
        return this.bridge.off(this.moduleName, eventName, listener)
    }

}