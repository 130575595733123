import {InternalApi} from './internal/internal-api'
import {Conversation} from './shared/conversation'
import {PersonInfo} from './shared/model/person-info'
import {Event, EventCallback} from './shared/internal/event'
import {EventEmitter, Listener} from './shared/internal/util/event-emitter'
import {ConversationType} from './shared/model/conversation-type'
import {AgentAvailabilityState} from './shared/model/agent-availability-state'
import {AgentAvailabilityEventType} from './shared/internal/module/agent-availability-module'
import {InternalModule} from './shared/internal/module/module'
import {UnbluApiError, UnbluErrorType} from './shared/unblu-api-error'
import {UnbluUiApi} from './unblu-api-ui'
import {MetaEventType} from "./internal/module/meta-module"
import {ConversationInfo} from "./shared/model/conversation-info"
import {NewConversationInterceptor} from "./shared/new-conversation-interceptor"
import {GeneralEventType} from "./internal/module/general-module"
import {InitializedUnbluApi} from "./shared/internal/initialized-unblu-api"
import {ConversationRecipient} from "./shared/model/conversation-recipient";
import {UnbluUtil} from "./shared/internal/unblu-util";

/**
 * Listener called whenever the active conversation changes.
 *
 * **Note:** If no conversation is currently active the passed conversation object will be `null`
 * @param conversation API object for the active conversation or `null` if no conversation is active.
 */
export type ConversationChangeListener = (conversation?: Conversation) => void

/**
 * Listener called whenever a conversation changed, added or removed from all conversations.
 * @param conversations All conversations of the current visitor.
 */
export type ConversationsChangeListener = (conversations: ConversationInfo[]) => void

/**
 * Listener called whenever the notification count of a person (i.e. unread messages) changes.
 * @param count The number of unseen notifications.
 */
export type NotificationCountChangeListener = (count: number) => void
/**
 * Listener called whenever the local person changes.
 * @param person Info about the person.
 */
export type PersonChangeListener = (person: PersonInfo) => void
/**
 * Listener called whenever there is activity by the person.
 * @param lastActivity A UTC timestamp when the last activity happened.
 */
export type PersonActivityListener = (lastActivity: Number) => void
/**
 * Listener called whenever the agent availability changes.
 * @param isAvailable A boolean that indicates if an agent is available.
 */
export type AgentAvailableChangeListener = (isAvailable: boolean) => void
/**
 * Listener called whenever the agent availability state changes.
 * @param availability The new availability state.
 */
export type AgentAvailabilityChangeListener = (availability: AgentAvailabilityState) => void
/**
 * Listener called whenever the UnbluApi gets de-initialized.
 */
export type DeinitializationListener = () => void

/**
 * #### This class represents the initialized Unblu Visitor JS API.
 *
 * There is only ever one instance of this api which can be retrieved via `unblu.api.initialize()`.
 * See {@link UnbluStaticApi} for more details on configuring and initializing the UnbluApi.
 *
 * The API connects to the integrated version of Unblu. All actions performed via the UnbluApi are executed in
 * the name of and with the rights of current visitor and may have direct effect on the displayed Unblu UI.
 *
 * For example if a conversation is started from the UnbluApi, the Unblu UI will navigate to it.
 * If a conversation is closed via the API, it will also be closed on the Unblu UI of the visitor.
 * For more information on UI side effects please check the documentation for each method call.
 *
 * For programmatic administrator access and configuration of Unblu please use the Unblu WebAPI.
 */
export class UnbluApi implements InitializedUnbluApi {

    /**
     * Event emitted every time the active conversation changes.
     *
     * This may happen due to a UI-navigation or an API-call.
     *
     * @event activeConversationChange
     * @see {@link on} for listener registration
     * @see {@link ConversationChangeListener}
     */
    public static readonly ACTIVE_CONVERSATION_CHANGE: 'activeConversationChange' = 'activeConversationChange'

    /**
     * Event emitted every time one of the conversations accessible to the current user changes or one is added or removed.
     *
     * @event conversationsChanged
     * @see {@link on} for listener registration
     * @see {@link ConversationsChangeListener}
     */
    public static readonly CONVERSATIONS_CHANGE: 'conversationsChange' = 'conversationsChange'

    /**
     * Event emitted every time the notification count (unread messages) changes.
     *
     * @event notificationCountChange
     * @see {@link on} for listener registration
     * @see {@link NotificationCountChangeListener}
     */
    public static readonly NOTIFICATION_COUNT_CHANGE: 'notificationCountChange' = 'notificationCountChange'

    /**
     * Event emitted every time the local person changes. This may be i.e. due to the person setting its name.
     *
     * @event personChange
     * @see {@link on} for listener registration
     * @see {@link PersonChangeListener}
     */
    public static readonly PERSON_CHANGE: 'personChange' = 'personChange'

    /**
     * Event emitted every time the local person has some activity inside Unblu.
     * This may be i.e. an interaction with the chat, a call, opening a conversation or interacting
     * with a co-browsing layer.
     *
     * The event can be used to reset the logout timer inside an authenticated area, for example.
     *
     * The configuration property com.unblu.conversation.activity.activityCategoriesToTrack specifies which categories of activity trigger the event.
     *
     * @event personActivity
     * @see {@link on} for listener registration
     * @see {@link PersonActivityListener}
     */
    public static readonly PERSON_ACTIVITY: 'personActivity' = 'personActivity'

    /**
     * Event emitted every time the agent availability changes for the current named area and locale.
     *
     * @event availableChange
     * @see {@link on} for listener registration
     * @see {@link AgentAvailableChangeListener}
     */
    public static readonly AGENT_AVAILABLE_CHANGE: 'availableChange' = 'availableChange'

    /**
     * Event emitted every time the agent availability state changes for the current named area and locale.
     *
     * @event availabilityChange
     * @see {@link on} for listener registration
     * @see {@link AgentAvailabilityChangeListener}
     */
    public static readonly AGENT_AVAILABILITY_CHANGE: 'availabilityChange' = 'availabilityChange'

    /**
     * Event emitted when this instance gets de-initialized and is not usable at the time until it fully got de-initialized.
     *
     * @event deinitializing
     * @see {@link on} for listener registration
     * @see {@link DeinitializationListener}
     */
    public static readonly DEINITIALIZING: 'deinitializing' = 'deinitializing'

    /**
     * Event emitted when this instance got de-initialized and has to be initialized again.
     *
     * @event deinitialized
     * @see {@link on} for listener registration
     * @see {@link DeinitializationListener}
     */
    public static readonly DEINITIALIZED: 'deinitialized' = 'deinitialized'     

    /**
     * Access the UI functionality over the UI property.
     */
    public ui: UnbluUiApi

    private internalListeners: { [key: string]: EventCallback } = {}
    private eventEmitter = new EventEmitter()

    /**
     * @hidden
     */
    constructor(private internalApi: InternalApi) {
        internalApi.meta.on('upgraded', () => this.onUpgraded())
        // All UI functionality is provided with the ui namespace
        this.ui = new UnbluUiApi(internalApi)
    }

    // Event
    /**
     * Registers an event listener for the given event.
     * @param event The activeConversationChange event.
     * @param listener The listener to be called.
     * @see {@link ACTIVE_CONVERSATION_CHANGE}
     */
    public on(event: typeof UnbluApi.ACTIVE_CONVERSATION_CHANGE, listener: ConversationChangeListener): void
    /**
     * Registers an event listener for the given event.
     * @param event The notificationCountChange event.
     * @param listener The listener to be called.
     * @see {@link NOTIFICATION_COUNT_CHANGE}
     */

    /**
     * Registers an event listener for the given event.
     * @param event The conversationsChanged event.
     * @param listener The listener to be called.
     * @see {@link CONVERSATIONS_CHANGE}
     */
    public on(event: typeof UnbluApi.CONVERSATIONS_CHANGE, listener: ConversationsChangeListener): void

    /**
     * Registers an event listener for the given event.
     * @param event The notificationCountChanged event.
     * @param listener The listener to be called.
     * @see {@link NOTIFICATION_COUNT_CHANGE}
     */
    public on(event: typeof UnbluApi.NOTIFICATION_COUNT_CHANGE, listener: NotificationCountChangeListener): void
    /**
     * Registers an event listener for the given event.
     * @param event The personChange event.
     * @param listener The listener to be called.
     * @see {@link PERSON_CHANGE}
     */
    public on(event: typeof UnbluApi.PERSON_CHANGE, listener: PersonChangeListener): void
    /**
     * Registers an event listener for the given event.
     * @param event The personActivity event.
     * @param listener The listener to be called.
     * @see {@link PERSON_ACTIVITY}
     */
    public on(event: typeof UnbluApi.PERSON_ACTIVITY, listener: PersonActivityListener): void
    /**
     * Registers an event listener for the given event.
     * @param event The agentAvailableChange event.
     * @param listener The listener to be called.
     * @see {@link AGENT_AVAILABLE_CHANGE}
     */
    public on(event: typeof UnbluApi.AGENT_AVAILABLE_CHANGE, listener: AgentAvailableChangeListener): void
    /**
     * Registers an event listener for the given event.
     * @param event The agentAvailabilityChange event.
     * @param listener The listener to be called.
     * @see {@link AGENT_AVAILABILITY_CHANGE}
     */
    public on(event: typeof UnbluApi.AGENT_AVAILABILITY_CHANGE, listener: AgentAvailabilityChangeListener): void
    /**
     * Registers an event listener for the given event.
     * @param event The deinitializing event.
     * @param listener The listener to be called.
     * @see {@link DEINITIALIZING}
     */
    public on(event: typeof UnbluApi.DEINITIALIZING, listener: DeinitializationListener): void
    /**
     * Registers an event listener for the given event.
     * @param event The deinitialized event.
     * @param listener The listener to be called.
     * @see {@link DEINITIALIZED}
     */
    public on(event: typeof UnbluApi.DEINITIALIZED, listener: DeinitializationListener): void

    public on(event: GeneralEventType | AgentAvailabilityEventType | MetaEventType, listener: Listener): void {
        this.assertNotDeinitialized()
        const needsInternalSubscription = !this.eventEmitter.hasListeners(event)
        this.eventEmitter.on(event, listener)
        if (needsInternalSubscription)
            this.onInternal(event).catch(e => console.warn('Error registering internal listener for event:', event, 'error:' + e, e))
    }

    /**
     * Removes a previously registered listener
     * @param event The event to unregister from.
     * @param listener The listener to remove.
     */
    public off(event: GeneralEventType | AgentAvailabilityEventType | MetaEventType, listener: Listener): boolean {
        this.assertNotDeinitialized()
        const removed = this.eventEmitter.off(event, listener)
        if (!this.eventEmitter.hasListeners(event))
            this.offInternal(event).catch(e => console.warn('Error removing internal listener for event:', event, 'error:' + e, e))
        return removed
    }

    private async onInternal(eventName: GeneralEventType | AgentAvailabilityEventType | MetaEventType) {
        let internalListener: EventCallback
        let internalModule: InternalModule<any, any>
        let needsUpgrade: boolean
        switch (eventName) {
            case UnbluApi.AGENT_AVAILABLE_CHANGE:
                internalListener = (event: Event<boolean>) => {
                    this.eventEmitter.emit(event.name, event.data)
                }
                internalModule = this.internalApi.agentAvailability
                needsUpgrade = false
                break
            case UnbluApi.AGENT_AVAILABILITY_CHANGE:
                internalListener = (event: Event<string>) => {
                    this.eventEmitter.emit(event.name, event.data)
                }
                internalModule = this.internalApi.agentAvailability
                needsUpgrade = false
                break
            case UnbluApi.ACTIVE_CONVERSATION_CHANGE:
                internalListener = (event: Event<string>) => {
                    this.eventEmitter.emit(event.name, event.data ? new Conversation(this.internalApi.conversation, event.data) : null)
                }
                internalModule = this.internalApi.general
                needsUpgrade = true
                break
            case UnbluApi.CONVERSATIONS_CHANGE:
                internalListener = (event: Event<string>) => {
                    this.eventEmitter.emit(event.name, event.data)
                }
                internalModule = this.internalApi.general
                needsUpgrade = true
                break
            case UnbluApi.NOTIFICATION_COUNT_CHANGE:
            case UnbluApi.PERSON_CHANGE:
            case UnbluApi.PERSON_ACTIVITY:            
                internalListener = (event: Event<string>) => {
                    this.eventEmitter.emit(event.name, event.data)
                }
                internalModule = this.internalApi.general
                needsUpgrade = true
                break
            case UnbluApi.DEINITIALIZING:
            case UnbluApi.DEINITIALIZED:
                internalListener = (event: Event<string>) => {
                    this.eventEmitter.emit(event.name, event.data)
                }
                internalModule = this.internalApi.meta
                needsUpgrade = false
                break
            default:
                throw new UnbluApiError(UnbluErrorType.INVALID_FUNCTION_ARGUMENTS, 'Registration to unknown event:' + eventName)
        }

        if (!needsUpgrade || await this.internalApi.meta.isUpgraded()) {
            this.internalListeners[eventName] = internalListener
            try {
                await internalModule.on(eventName, internalListener)
            } catch (e) {
                delete this.internalListeners[eventName]
                throw e
            }
        }
    }

    private async offInternal(eventName: GeneralEventType | AgentAvailabilityEventType | MetaEventType) {
        const listener = this.internalListeners[eventName]
        if (listener == null) {
            return
        }
        delete this.internalListeners[eventName]

        let internalModule: InternalModule<any, any>
        switch (eventName) {
            case UnbluApi.AGENT_AVAILABILITY_CHANGE:
                internalModule = this.internalApi.agentAvailability
                break
            case UnbluApi.DEINITIALIZING:
            case UnbluApi.DEINITIALIZED:
                internalModule = this.internalApi.meta
                break
            default:
                internalModule = this.internalApi.general
                break
        }
        await internalModule.off(eventName, listener)
    }

    // General

    /**
     * Returns information about the visitor.
     * @return A promise that resolves to the current visitors person info.
     */
    public async getPersonInfo(): Promise<PersonInfo> {
        this.assertNotDeinitialized()
        await this.requireUpgrade()
        return this.internalApi.general.getPersonInfo()
    }

    /**
     * Sets the current visitor's nickname.
     * This could be set before or during a conversation.
     * @return A promise that resolves empty when the operation is done
     */
    public async setPersonNickname(nickname: string): Promise<void> {
        this.assertNotDeinitialized()
        await this.requireUpgrade()
        return this.internalApi.general.setPersonNickname(nickname)
    }

    /**
     * Logs the current visitor in using the `authenticator/loginWithSecureToken` web API endpoint.
     * Depending on the configuration, existing conversation may will be transferred to the authenticated user.
     * @param accessToken The access token (JWT) to authenticate the visitor with
     * @return A promise that resolves empty if the login succeeds or is rejected if it fails
     */
    public async login(accessToken: string): Promise<void> {
        this.assertNotDeinitialized()
        return UnbluUtil.loginWithSecureToken(this.internalApi.configuration.serverUrl || '', this.internalApi.configuration.apiKey, this.internalApi.configuration.entryPath || '/unblu', accessToken)
    }

    /**
     * Checks if the current visitor is authenticated.
     * @return A promise that resolves to a boolean if the visitor is authenticated
     */
    public async isAuthenticated(): Promise<boolean> {
        this.assertNotDeinitialized()
        return UnbluUtil.isAuthenticated(this.internalApi.configuration.serverUrl || '', this.internalApi.configuration.entryPath || '/unblu')
    }

    /**
     * Logs the visitor out.
     * The user will not have access to conversations from the authenticated visitor he was before anymore. He needs to be authenticated again for that.
     * @return A promise that resolves empty when the logout succeeds or is rejected if it fails
     */
    public async logout(): Promise<void> {
        this.assertNotDeinitialized()
        return UnbluUtil.logout(this.internalApi.configuration.serverUrl || '', this.internalApi.configuration.entryPath || '/unblu')
    }

    /**
     * Returns the number of unread messages.
     * @return A promise that resolves to the current number of unread messages.
     */
    public async getNotificationCount(): Promise<number> {
        this.assertNotDeinitialized()
        if (await this.internalApi.meta.isUpgraded()) {
            return this.internalApi.general.getNotificationCount()
        } else {
            return this.internalApi.generalLazy.getNotificationCount()
        }
    }

    // Conversation


    /**
     * Starts a new Conversation and places it into the inbound conversation queue.
     *
     * Starting a new conversation involves an agent availability check.
     * For {@link ConversationType.OFFLINE_CHAT_REQUEST} conversations, the check proceeds as follows:
     * * If an agent is available, the conversation type will be changed to {@link ConversationType.CHAT_REQUEST}.
     * * If no agents are available, it will start an offline conversation provided offline chat requests are enabled in the Unblu server's configuration.
     * * if offline chat requests aren't enabled, the request will be rejected.
     *
     * For all `online` conversation types, the check works as follows:
     * * If an agent is available, the conversation will be started.
     * * If no agents are available, the request will be rejected.
     *
     * You should therefore always check agent availability before starting a new conversation.
     * If no agents are available, only start conversations of the type {@link ConversationType.OFFLINE_CHAT_REQUEST}.
     *
     * **NOTE:** calling this method will NOT automatically open the Unblu UI if it is collapsed. Use [ui.openIndividualUi()]{@link UnbluUiApi.openIndividualUi} if this is needed.
     *
     * @param type The conversation type that shall be started.
     * @param visitorName The name the local visitor should have. This is only taken into account if the visitor is not already authenticated.
     * @param visitorData Custom data for the visitor in any format. **NOTE:** The data which is passed here could be used in [NewConversationCallback]{@link NewConversationInterceptor}
     * @param recipient The team or agent recipient of the conversation. This will overwrite any named area that might be set for this web page. **NOTE:** The data which is passed here could be used in [NewConversationCallback]{@link NewConversationInterceptor}
     * @return A promise that resolves to the conversation object giving API access to the started conversation.
     */
    public async startConversation(type: ConversationType, visitorName?: string, visitorData?: string, recipient?: ConversationRecipient): Promise<Conversation> {
        this.assertNotDeinitialized()
        await this.requireUpgrade()
        const conversationId = await this.internalApi.general.startConversation(type, visitorName, visitorData, recipient)
        return new Conversation(this.internalApi.conversation, conversationId)
    }

    /**
     * Set a custom interceptor which will be triggered when a new conversation is started (initiated from UI or JavaScript).
     * @param callback The interceptor which is called before a new conversation is started. The Callback is of type [NewConversationCallback]{@link NewConversationInterceptor}
     * @return A promise that resolves when the interceptor is successfully applied and active.
     */
    public async setNewConversationInterceptor(callback: NewConversationInterceptor): Promise<void> {
        this.assertNotDeinitialized()
        if (!await this.internalApi.meta.isUpgraded()) {
            return await this.internalApi.generalLazy.setNewConversationInterceptor(callback)
        } else {
            return await this.internalApi.general.setNewConversationInterceptor(callback)
        }
    }

    /**
     * Joins an existing conversation with a given PIN.
     *
     * **NOTE:** calling this method will NOT automatically open the Unblu UI if it is collapsed. Use [ui.openIndividualUi()]{@link UnbluUiApi.openIndividualUi} if this is needed.
     *
     * @param pin The PIN retrieved from the Unblu Agent Desk.
     * @param visitorName The name the local visitor should have. This is only taken into account if the visitor is not already authenticated.
     * @return A promise that resolves to the conversation object giving API access to the joined conversation.
     */
    public async joinConversation(pin: string, visitorName?: string): Promise<Conversation> {
        this.assertNotDeinitialized()
        await this.requireUpgrade()
        const conversationId = await this.internalApi.general.joinConversation(pin, visitorName)
        return new Conversation(this.internalApi.conversation, conversationId)
    }

    /**
     * Opens the existing conversation with the given conversation ID.
     *
     * **NOTE:** calling this method will NOT automatically open the Unblu UI if it is collapsed. Use [ui.openIndividualUi()]{@link UnbluUiApi.openIndividualUi} if this is needed.
     *
     * @param conversationId The id of the conversation to be opened.
     * @return A promise that resolves to the conversation object giving API access to the opened conversation.
     */
    public async openConversation(conversationId: string): Promise<Conversation> {
        this.assertNotDeinitialized()
        await this.requireUpgrade()
        await this.internalApi.general.openConversation(conversationId)
        return new Conversation(this.internalApi.conversation, conversationId)
    }

    /**
     * Returns the currently active conversation or `null` if no conversation is active.
     *
     * **NOTE:** calling this method twice while the same conversation is active, will result in two individual conversation API instances being returned.
     * destroying one of them will not cause the other one to also be destroyed. If however the active conversation is closed, all returned Conversation instances will be destroyed.
     *
     * @return A promise that either resolves to the currently active conversation or `null` if no conversation is open.
     * @see {@link ACTIVE_CONVERSATION_CHANGE}
     */
    public async getActiveConversation(): Promise<Conversation | null> {
        this.assertNotDeinitialized()
        if (await this.internalApi.meta.isUpgraded()) {
            const conversationId = await this.internalApi.general.getActiveConversation()
            return conversationId != null ? new Conversation(this.internalApi.conversation, conversationId) : null
        } else {
            return null
        }
    }

    /**
     * Returns all conversations of the current visitor. If no conversation is present, an empty array is returned.
     *
     * @return A promise that resolves to an array of [ConversationInfo]{@link ConversationInfo}.
     */
    public async getConversations(): Promise<ConversationInfo[]> {
		this.assertNotDeinitialized()
        await this.requireUpgrade()
        return await this.internalApi.general.getConversations()
    }

    /**
     * Checks if an agent is available for the current named area and language.
     *
     * @return Promise that resolves to `true` if the availability state is [AVAILABLE]{@link AgentAvailabilityState.AVAILABLE} or [BUSY]{@link AgentAvailabilityState.BUSY}, `false` otherwise.
     * @see {@link getAgentAvailabilityState} for a more detailed check.
     */
    public async isAgentAvailable(): Promise<boolean> {
        this.assertNotDeinitialized()
        return this.internalApi.agentAvailability.isAgentAvailable()
    }

    /**
     * Returns the current availability state for the current named area and language.
     * @return Promise that resolves to the current availability state.
     * @see {@link isAgentAvailable} for a simpler check.
     */
    public async getAgentAvailabilityState(): Promise<AgentAvailabilityState> {
        this.assertNotDeinitialized()
        return this.internalApi.agentAvailability.getAgentAvailabilityState()
    }

    private async requireUpgrade(): Promise<void> {
        await this.internalApi.meta.upgrade(false)
    }

    private onUpgraded() {
        for (let event of this.eventEmitter.getEventsWithListeners()) {
            // register internal listeners for all events that need upgrade.
            if (!this.internalListeners[event])
                this.onInternal(event as GeneralEventType)
        }
    }

    private assertNotDeinitialized() {
        if (this.isDeinitialized()) {
            throw new UnbluApiError(UnbluErrorType.ILLEGAL_STATE, 'Error: trying to execute method on deinitialized UnbluApi instance.')
        }
    }

    public isDeinitialized(): Boolean {
        return this.internalApi == null
    }

    /**
     * De-initializes the API. It will destroy the UI, all connections and will release all resources (as far as it is technically possible).
     *
     * Afterwards the API can be initialized again via  [window.unblu.api.initialize()]{@link UnbluStaticApi.initialize}
     */
    public async deinitialize(): Promise<void> {
        if (this.isDeinitialized()) {
            return
        }
        await this.internalApi.meta.deinitialize()
        this.internalApi = null
        this.ui = null
        this.internalListeners = null
        this.eventEmitter = null
    }

}