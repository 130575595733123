import {IndividualUiState} from "../../model/individualui_state";
import {
    BaseGeneralEventType,
    BaseGeneralFunction,
    BaseGeneralModule
} from "../../shared/internal/module/base-general-module";
import {ActiveIndividualUiView} from "../../model/individualui_component";

export type GeneralEventType =
    BaseGeneralEventType |
    'uiStateChange' |
    'uiActiveIndividualUiViewChange';

const enum GeneralFunction {
    toggleIndividualUi = 'toggleIndividualUi',
    popoutIndividualUi = 'popoutIndividualUi',
    popinIndividualUi = 'popinIndividualUi',
    maximizeIndividualUi = 'maximizeIndividualUi',
    minimizeIndividualUi = 'minimizeIndividualUi',
    openIndividualUi = 'openIndividualUi',
    openPinEntryUi = 'openPinEntryUi',
    openOverviewUi = 'openOverviewUi',
    collapseIndividualUi = 'collapseIndividualUi',
    getIndividualUiState = 'getIndividualUiState',
    getActiveIndividualUiView = 'getActiveIndividualUiView'
}

export class GeneralModule extends BaseGeneralModule<GeneralFunction | BaseGeneralFunction, GeneralEventType> {

    public toggleIndividualUi(): Promise<void> {
        return this.callApiFunction(GeneralFunction.toggleIndividualUi, []);
    }

    public openPinEntryUi(): Promise<void> {
        return this.callApiFunction(GeneralFunction.openPinEntryUi, []);
    }

    public openOverviewUi(): Promise<void> {
        return this.callApiFunction(GeneralFunction.openOverviewUi, []);
    }

    public popoutIndividualUi(): Promise<void> {
        return this.callApiFunction(GeneralFunction.popoutIndividualUi, []);
    }

    public popinIndividualUi(): Promise<void> {
        return this.callApiFunction(GeneralFunction.popinIndividualUi, []);
    }

    public maximizeIndividualUi(): Promise<void> {
        return this.callApiFunction(GeneralFunction.maximizeIndividualUi, []);
    }

    public minimizeIndividualUi(): Promise<void> {
        return this.callApiFunction(GeneralFunction.minimizeIndividualUi, []);
    }

    public openIndividualUi(): Promise<void> {
        return this.callApiFunction(GeneralFunction.openIndividualUi, []);
    }

    public collapseIndividualUi(): Promise<void> {
        return this.callApiFunction(GeneralFunction.collapseIndividualUi, []);
    }

    public getIndividualUiState(): Promise<IndividualUiState> {
        return this.callApiFunction(GeneralFunction.getIndividualUiState, []);
    }

    public getActiveIndividualUiView(): Promise<ActiveIndividualUiView> {
        return this.callApiFunction(GeneralFunction.getActiveIndividualUiView, []);
    }
}
