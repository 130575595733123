import {UnbluApi} from './unblu-api';
import {EventEmitter, Listener} from './shared/internal/util/event-emitter';
import {IntegrationType, UnbluUtil} from './shared/internal/unblu-util';
import {UnbluApiError, UnbluErrorType} from './shared/unblu-api-error';
import {ApiBridge} from './shared/internal/api-bridge';
import {InternalApi} from './internal/internal-api';
import {ApiState} from "./shared/api-state";
import {Configuration} from "./shared/model/configuration";
import {UnbluApiFactory} from "./shared/internal/unblu-api-factory";

export {Configuration};

export type ReadyListener = (api: UnbluApi) => void;
export type ErrorListener = (e: Error) => void;
export type DeinitializingListener = () => void;
export type DeinitializedListener = () => void;
export type StateListener = (state: ApiState) => void;


/**
 * #### The central entry point that allows to configure an initialize the Unblu Visitor JS API.
 * The static Unblu API works without actually loading the rest of Unblu.
 * It can do some general checks and load Unblu or connect the API to a loaded version of Unblu.
 * The JS API is an optional add-on to the Unblu visitor site integration.
 *
 * Depending on how Unblu is integrated into the local website the API has to be initialized differently.
 *
 * **a.) API-only integration**
 * If no unblu-snippet is loaded into the page, Unblu can be fully initialized with the API.
 * In this case, both the `configure` and the `initialize` methods have to be called.
 * Example:
 * ```ts
 *  const api = await unblu.api
 *      // configure the unblu server
 *      .configure({
 *          apiKey: "<your-api-key>",
 *          serverUrl: "<unblu-server-url>"
 *      })
 *      // initialize the api.
 *      .initialize();
 * ```
 * This implementation will load the Unblu snippet and initialize both Unblu and the JS API.
 *
 * **b.) Snippet and JS API integration**
 * If the Unblu snippet is already present in the local website, Unblu doesn't have to be loaded
 * and only the API has to be initialized.
 * Example:
 * ```ts
 * // directly initialize the api without configuring.
 * const api = await unblu.api.initialize();
 *
 * ```
 */
export class UnbluStaticApi implements UnbluApiFactory{
    private state: ApiState = ApiState.INITIAL;
    private error: UnbluApiError;
    private eventEmitter = new EventEmitter();

    private configuration: Configuration;
    private initializedApi: UnbluApi;

    /**
     * Event emitted as soon as the API is initialized.
     *
     * It usually makes sense to use this event if there is some general action that has to be triggered when the API is initialized,
     * but there are several places in the integration code that may trigger the initialization.
     *
     * In most cases however, it is better to use
     * ```ts
     * unblu.api.initialize().then(api => { //use api here });
     * ```
     * or
     * ```ts
     * let api = await unblu.api.initialize();
     * // use api here
     * ```
     *
     * Note: that this event will be triggered again after each initialization.
     *
     * @event ready
     * @see {@link on} for listener registration
     */
    public static readonly READY: 'ready' = 'ready';

    /**
     * Event emitted if the API initialization fails.
     *
     * It usually makes sense to use this event if there is some general action that has to be triggered when the API initialization fails,
     * but there are several places in the integration code that may trigger the initialization.
     *
     * In most cases however, it is better to use
     * ```ts
     * unblu.api.initialize().catch(error=> { //handle error here });
     * ```
     * or
     * ```ts
     * try{
     *      let api = await unblu.api.initialize();
     * }catch(e){
     *     // handle error here
     * }
     *
     * ```
     *
     * @event error
     * @see {@link on} for listener registration
     */
    public static readonly ERROR: 'error' = 'error';

    /**
     * Event emitted as soon as the API is going to get de-initialized.
     *
     * It usually makes sense to use this event to clean up resources and/or unregistering of listeners to no try to use the API again until it is initialized again.
     *
     * @event deinitializing
     * @see {@link on} for listener registration
     */
    public static readonly DEINITIALIZING: 'deinitializing' = 'deinitializing';

    /**
     * Event emitted as soon as the API is completely de-initialized.
     *
     * It usually makes sense to use this event to clean up resources and/or unregistering of listeners to no try to use the API again until it is initialized again.
     *
     * @event deinitialized
     * @see {@link on} for listener registration
     */
    public static readonly DEINITIALIZED: 'deinitialized' = 'deinitialized';

    /**
     * Event emitted whenever the API state changes
     *
     * @event state
     * @see {@link on} for listener registration
     */
    public static readonly STATE: 'state' = 'state';

    /**
     * @hidden
     */
    constructor() {
        // store the error
        this.eventEmitter.on(UnbluStaticApi.ERROR, e => this.error = e);

        // install globally if needed so the embedded API as a reference it can use to de-init
        const unblu = UnbluUtil.getUnbluObject();
        if (unblu.api) {
            this.handleError(new UnbluApiError(UnbluErrorType.ILLEGAL_STATE, 'Unblu API has already been loaded.'));
        } else {
            unblu.api = this;
        }

        if (UnbluUtil.isUnbluLoaded(IntegrationType.floating)) {
            // Auto init if snippet is already loaded.
            this.initializeApi().catch(e => console.warn('Error during auto initialization', e));
        }
    }

    //TODO: Add version

    /**
     * Registers an event listener for the given event.
     *
     * **Note** If the API is already initialized, this listener will be called directly.
     * @param event The ready event
     * @param listener The listener to be called.
     * @see {@link READY}
     */
    on(event: typeof UnbluStaticApi.READY, listener: ReadyListener): void;

    /**
     * Registers an event listener for the given event.
     *
     * **Note** If the API has already failed, this listener will be called directly.
     * @param event The error event
     * @param listener The listener to be called.
     * @see {@link ERROR}
     */
    on(event: typeof UnbluStaticApi.ERROR, listener: ErrorListener): void;

    /**
     * Registers an event listener for the given event.
     *
     * **Note** If the API is already deinitializing, this listener will be called directly.
     * @param event The deinitializing event
     * @param listener The listener to be called.
     * @see {@link DEINITIALIZING}
     */
    on(event: typeof UnbluStaticApi.DEINITIALIZING, listener: DeinitializingListener): void;

    /**
     * Registers an event listener for the given event.
     *
     * **Note** If the API is already deinitialized, this listener will be called directly.
     * @param event The deinitialized event
     * @param listener The listener to be called.
     * @see {@link DEINITIALIZED}
     */
    on(event: typeof UnbluStaticApi.DEINITIALIZED, listener: DeinitializedListener): void;

    /**
     * Registers an event listener for the given event.
     *
     * @param event The state event
     * @param listener The listener to be called.
     * @see {@link STATE}
     */
    on(event: typeof UnbluStaticApi.STATE, listener: StateListener): void;

    on(event: string, listener: Listener): void {
        if (event == UnbluStaticApi.READY && this.state == ApiState.INITIALIZED)
            listener(this.initializedApi);
        else if (event == UnbluStaticApi.ERROR && this.state == ApiState.ERROR)
            listener(this.error);
        else if (event == UnbluStaticApi.DEINITIALIZING && this.state == ApiState.DEINITIALIZING)
            listener();
        else if (event == UnbluStaticApi.DEINITIALIZED && this.state == ApiState.DEINITIALIZED)
            listener();

        this.eventEmitter.on(event, listener)
    }

    /**
     * Removes a previously registered listener.
     * @param event The event unregister.
     * @param listener The listener to be removed.
     * @return `true` if the listener was removed, `false` otherwise.
     */
    off(event: string, listener: Listener): boolean {
        return this.eventEmitter.off(event, listener);
    }

    /**
     * Checks whether the API has to be configured or not.
     *
     * - If no snippet is present and the API state is still [INITIAL]{@link ApiState.INITIAL} a configuration is necessary.
     * - If a snippet is present or the API is already loaded, configuration is not necessary.
     * - If the API state is in [DEINITIALIZED]{@link ApiState.DEINITIALIZED}
     *
     * @return `true` if a configuration is needed to initialize the API, `false` otherwise.
     * @see {@link configure} to configure the API
     * @see {@link initialize} to initialize the API
     */
    public isConfigurationNeeded(): boolean {
        return (this.state === ApiState.INITIAL || this.state === ApiState.DEINITIALIZED) && !UnbluUtil.isUnbluLoaded(IntegrationType.floating);
    }

    /**
     * Returns the current state of the API
     * @return the current API state.
     * @see {@link isInitialized} for a simpler check
     */
    public getApiState(): ApiState {
        return this.state;
    }

    /**
     * Checks whether the API is initialized or not.
     * @return `true` if the API is initialized, `false` for any other state.
     * @see {@link getApiState} for the full state
     */
    public isInitialized(): boolean {
        return this.state === ApiState.INITIALIZED;
    }

    /**
     * Configures the way that Unblu should be initialized.
     *
     * The configuration of the Unblu API is needed when, and only when no Unblu snippet is already present in the website.
     *
     * **Note:**
     * - Calling this method when there's already an Unblu snippet will result in an {@link UnbluApiError}.
     * - This method must be called BEFORE {@link initialize}.
     * If it is called afterwards an {@link UnbluApiError} will be thrown.
     *
     * @param config The configuration to be set.
     * @return an instance of `this` allowing chaining like `unblu.api.configure({...}).initialize();`
     * @see {@link isConfigurationNeeded} to check if configuration is needed or not.
     */
    configure(config: Configuration): UnbluStaticApi {
        if (UnbluUtil.isUnbluLoaded(IntegrationType.floating)) {
            throw new UnbluApiError(UnbluErrorType.ILLEGAL_STATE, 'Configure called when Unblu was already loaded.')
        } else if (this.state !== ApiState.INITIAL && this.state !== ApiState.DEINITIALIZED) {
            throw new UnbluApiError(UnbluErrorType.ILLEGAL_STATE, 'Error configure called after API was already initialized or is not fully deinitialized yet.');
        }
        this.configuration = {...config};
        return this;
    }

    /**
     * Initializes the API and resolves to the fully initialized API.
     *
     * If the API has already been initialized or is already in the initializing process, the existing API will be returned.
     * There is only ever one instance of the API which will be returned by any call of this method which makes it safe to call this multiple times.
     *
     * *The initialization may fail with a {@link UnbluApiError} for the following reasons*
     * - A configuration is needed but none was provided: [CONFIGURATION_MISSING]{@link UnbluErrorType.CONFIGURATION_MISSING}
     * - Loading Unblu encounters a problem: [ERROR_LOADING_UNBLU]{@link UnbluErrorType.ERROR_LOADING_UNBLU}
     * - The initialization timed out: [INITIALIZATION_TIMEOUT]{@link UnbluErrorType.INITIALIZATION_TIMEOUT}
     * - The Unblu API is incompatible with the Unblu server: [INCOMPATIBLE_UNBLU_VERSION]{@link UnbluErrorType.INCOMPATIBLE_UNBLU_VERSION}
     * - The browser is unsupported: [UNSUPPORTED_BROWSER]{@link UnbluErrorType.UNSUPPORTED_BROWSER}
     * - The provided access token is invalid: [AUTHENTICATION_FAILED]{@link UnbluErrorType.AUTHENTICATION_FAILED}
     */
    public async initialize(): Promise<UnbluApi> {
        if (this.state === ApiState.INITIALIZED) {
            return this.initializedApi;
        } else if (this.state === ApiState.INITIALIZING) {
            return new Promise<UnbluApi>((resolve, reject) => {
                this.on(UnbluStaticApi.READY, resolve);
                this.on(UnbluStaticApi.ERROR, reject);
            });
        } else if(this.state === ApiState.DEINITIALIZING) {
            return Promise.reject('Cannot initialize while de-initializing is ongoing! Please wait for the deinitialized event')
        } else {
            return this.initializeApi();
        }
    }

    private async initializeApi(): Promise<UnbluApi> {
        this.state = ApiState.INITIALIZING;
        await UnbluUtil.deinitializeEmbeddedIfNeeded();
        try {
            if (!UnbluUtil.isUnbluLoaded(IntegrationType.floating)) {
                if (!this.configuration) {
                    // noinspection ExceptionCaughtLocallyJS
                    throw new UnbluApiError(UnbluErrorType.CONFIGURATION_MISSING, 'No Unblu snippet present and no configuration provided. Use configure if you want to initialize Unblu without having the Unblu snippet loaded.')
                }
                if (this.configuration.namedArea) {
                    UnbluUtil.setNamedArea(this.configuration.namedArea);
                }
                if (this.configuration.locale) {
                    UnbluUtil.setLocale(this.configuration.locale);
                }
                if(this.configuration.accessToken) {
                    await UnbluUtil.loginWithSecureToken(this.configuration.serverUrl || '', this.configuration.apiKey, this.configuration.entryPath || '/unblu', this.configuration.accessToken);
                }
                await UnbluStaticApi.injectUnblu(this.configuration);
            } else if(!this.configuration) {
                //Unblu already loaded (potentially via snippet) with no explicit configuration of the API. Generated configuration based on the loaded instance
                this.configuration = UnbluUtil.generateConfigurationFromLoadedUnblu();
            }
            let apiBridge = new ApiBridge(UnbluUtil.getUnbluObject(), 'internal');
            await apiBridge.waitUntilLoaded(this.configuration.initTimeout || 30000);

            let internalApi = new InternalApi(apiBridge, this.configuration);
            internalApi.checkCompatibility();

            // Check internalApi waitUntilInitialized
            await internalApi.meta.waitUntilInitialized();

            this.initializedApi = new UnbluApi(internalApi);
            this.initializedApi.on(UnbluApi.DEINITIALIZING, () => this.onDeinitializing());
            this.initializedApi.on(UnbluApi.DEINITIALIZED, () => this.onDeinitialized());
            this.state = ApiState.INITIALIZED;
        } catch (e) {
            this.handleError(e);
        }

        this.eventEmitter.emit(UnbluStaticApi.READY, this.initializedApi);
        this.eventEmitter.emit(UnbluStaticApi.STATE, this.state);

        return this.initializedApi;
    }

    private static async injectUnblu(config: Configuration): Promise<void> {
        const serverUrl = config.serverUrl || '';
        const apiKey = config.apiKey || '';
        const unbluPath = config.entryPath || '/unblu';
        let unbluUrl = `${serverUrl}${unbluPath}/visitor.js?x-unblu-apikey=${apiKey}`;
        try {
            await UnbluUtil.loadScript(unbluUrl, config.initTimeout);
        } catch (e) {
            throw new UnbluApiError(UnbluErrorType.ERROR_LOADING_UNBLU, 'Error loading unblu snippet: ' + e + ' check the configuration: ' + config);
        }
    }

    private handleError(error: UnbluApiError) {
        this.state = ApiState.ERROR;
        this.eventEmitter.emit(UnbluStaticApi.ERROR, error);
        this.eventEmitter.emit(UnbluStaticApi.STATE, this.state);
        if (UnbluErrorType.UNSUPPORTED_BROWSER != error.type) { 
        	console.error(error);
        }
        throw error;
    }

    private onDeinitializing() {
        this.initializedApi = null;
        this.state = ApiState.DEINITIALIZING;
        this.eventEmitter.emit(UnbluStaticApi.DEINITIALIZING);
        this.eventEmitter.emit(UnbluStaticApi.STATE, this.state);
    }

    private onDeinitialized() {
        this.state = ApiState.DEINITIALIZED;
        this.eventEmitter.emit(UnbluStaticApi.DEINITIALIZED);
        this.eventEmitter.emit(UnbluStaticApi.STATE, this.state);
        if (this.configuration.namedArea) {
            UnbluUtil.removeNamedArea()
        }
    }
}