export enum ConversationState {
    /**
     * Initial state of a conversation
     */
    CREATED = 'CREATED',

    /**
     * Depending of the engagement type of the conversation, the conversation stays in the onboarding state as long as the person in the center of the conversation
     * is doing his onboarding
     */
    ONBOARDING = 'ONBOARDING',

    /**
     * When a message is send to an unassigned conversation, the conversation first is put into the reboarding state as long as the person in the center of the
     * conversation is doing the reboarding.
     */
    REBOARDING = 'REBOARDING',

    /**
     * Depending of the engagement type of the conversation, the conversation is put in the queued state when it is inside the queue.
     */
    QUEUED = 'QUEUED',

    /**
     * Conversation is active
     */
    ACTIVE = 'ACTIVE',

    /**
     * After there is no assignee of a conversation anymore, the conversation goes to unassigned state until a message from a visitor is written to start reboarding
     * again.
     */
    UNASSIGNED = 'UNASSIGNED',

    /**
     * Conversation is being ended
     */
    OFFBOARDING = 'OFFBOARDING',

    /**
     * Conversation is ended
     */
    ENDED = 'ENDED'
}