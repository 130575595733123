import {InternalModule} from '../../shared/internal/module/module';
import {ApiBridge} from '../../shared/internal/api-bridge';
import {PersonInfo} from '../../shared/model/person-info';
import {NewConversationInterceptor} from "../../shared/new-conversation-interceptor";


export type GeneralLazyEventType = '';

const enum GeneralLazyFunction {
    getNotificationCount = 'getNotificationCount',
    getPersonInfo = 'getPersonInfo',
    setNewConversationInterceptor = 'setNewConversationInterceptor',
}

export class GeneralLazyModule extends InternalModule<GeneralLazyFunction, GeneralLazyEventType> {
    constructor(bridge: ApiBridge) {
        super(bridge, 'generallazy');
    }

    public getNotificationCount(): Promise<number> {
        return this.callApiFunction(GeneralLazyFunction.getNotificationCount, []);
    }

    public getPersonInfo(): Promise<PersonInfo> {
        return this.callApiFunction(GeneralLazyFunction.getPersonInfo, []);
    }

    public setNewConversationInterceptor(callback: NewConversationInterceptor): Promise<void> {
        return this.callApiFunction(GeneralLazyFunction.setNewConversationInterceptor, [callback]);
    }
}