export enum InvitationStatus {
    /**
     * Invitation is open however it may have expired.
     */
    OPEN = 'OPEN',
    /**
     * Invitation is redeemed.
     */
    REDEEMED = 'REDEEMED',
    /**
     * Invitation is revoked.
     */
    REVOKED = 'REVOKED'
}