import {
    AgentAvailabilityChangeListener,
    AgentAvailableChangeListener,
    ConversationChangeListener,
    ConversationsChangeListener,
    NotificationCountChangeListener,
    PersonChangeListener,
    PersonActivityListener,
    UnbluApi
} from './unblu-api'
import {
    CallStateListener,
    ConnectionStateListener,
    Conversation,
    ConversationStateListener,
    InvitationsListener,
    CustomActionInvocationListener
} from './shared/conversation'
import {NewConversationInterceptor} from "./shared/new-conversation-interceptor"
import {NewConversationInterceptorResult} from "./shared/model/new-conversation-interceptor-result"
import {ApiState} from "./shared/api-state"
import {Configuration, ErrorListener, ReadyListener, UnbluStaticApi} from './unblu-static-api'
import {UnbluUtil} from './shared/internal/unblu-util'
import {CallState} from './shared/model/call-state'
import {ConnectionState} from './shared/model/connection-state'
import {ConversationType} from './shared/model/conversation-type'
import {ConversationRecipient} from "./shared/model/conversation-recipient"
import {ConversationRecipientType} from "./shared/model/conversation-recipient-type"
import {AgentAvailabilityState} from './shared/model/agent-availability-state'
import {ConversationState} from './shared/model/conversation-state'
import {UnbluApiError, UnbluErrorType} from './shared/unblu-api-error'
import {IndividualUiState} from "./model/individualui_state"
import {ActiveIndividualUiView} from "./model/individualui_component"
import {InvitationStatus} from "./shared/model/invitation-status"
import {UiActiveIndividualUiViewChangeListener,UiStateChangeListener, UnbluUiApi} from "./unblu-api-ui"
import {ConversationInfo} from "./shared/model/conversation-info"
import {Invitation} from "./shared/model/invitation"
import {PersonInfo} from "./shared/model/person-info"
import {CustomActionInvocation} from "./shared/model/customaction/custom-action-invocation"

export {AgentAvailabilityState}
export {ApiState, UnbluStaticApi, Configuration, ErrorListener, ReadyListener}
export {CallState}
export {ConnectionState}
export {ConversationState}
export {ConversationType}
export {IndividualUiState}
export {ActiveIndividualUiView}
export {InvitationStatus}
export {UnbluErrorType}
export {Conversation, CallStateListener, ConnectionStateListener, ConversationStateListener, InvitationsListener, CustomActionInvocationListener}
export {
    UnbluApi,
    AgentAvailableChangeListener,
    AgentAvailabilityChangeListener,
    ConversationChangeListener,
    ConversationsChangeListener,
    NewConversationInterceptor,
    NewConversationInterceptorResult,
    ConversationRecipient,
    ConversationRecipientType,
    NotificationCountChangeListener,
    PersonChangeListener,
    PersonActivityListener
}
export {UnbluApiError}
export {UnbluUiApi, UiActiveIndividualUiViewChangeListener, UiStateChangeListener}
export {ConversationInfo}
export {Invitation}
export {PersonInfo}
export {CustomActionInvocation}

/**
 * The main unblu namespace which gives access to the Unblu API.
 *
 * Access:
 * ```javascript
 * window.unblu.api
 * ```
 *
 * **Note:** all fields inside the unblu namespace except for the {@link api} are not public and may change without any notice.
 */
export interface Unblu {
    /**
     * Access to the Unblu API.
     *
     * This field will be available as soon as the Unblu API has been loaded.
     */
    api?: UnbluStaticApi,

    AgentAvailabilityState?: typeof AgentAvailabilityState,

    CallState?: typeof CallState,

    ConnectionState?: typeof ConnectionState,

    ConversationState?: typeof ConversationState,

    ConversationType?: typeof ConversationType,

    UnbluErrorType?: typeof UnbluErrorType
}

/**
 * Global window scope definition of the unblu namespace on the window.
 * Access:
 * ```javascript
 * window.unblu.api
 * ```
 * @noInheritDoc
 */
//this is only for documentation as typedoc does not support global objects any more
export declare interface Globals extends Window {
    /**
     * The main unblu namespace
     */
    unblu: Unblu
}

// TODO: remove in v8
const unblu = (UnbluUtil.getUnbluObject() || UnbluUtil.createUnbluObject()) as Unblu
unblu.AgentAvailabilityState = AgentAvailabilityState
unblu.CallState = CallState
unblu.ConnectionState = ConnectionState
unblu.ConversationState = ConversationState
unblu.ConversationType = ConversationType
unblu.UnbluErrorType = UnbluErrorType

export default {
    /**
     * Access to the Unblu API.
     *
     * This field will be available as soon as the Unblu API has been loaded.
     */
    api: new UnbluStaticApi(),

    AgentAvailabilityState: AgentAvailabilityState,

    CallState: CallState,

    ConnectionState: ConnectionState,

    ConversationState: ConversationState,

    ConversationType: ConversationType,

    UnbluErrorType: UnbluErrorType
}



