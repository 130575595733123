import {InternalModule} from '../../shared/internal/module/module'
import {ApiBridge} from '../../shared/internal/api-bridge'

export type MetaEventType =
    'upgraded' |
    'deinitializing' |
    'deinitialized'


const enum MetaFunction {
    isUpgraded = 'isUpgraded',
    upgrade = 'upgrade',
    waitUntilInitialized = 'waitUntilInitialized',
    deinitialize = 'deinitialize'
}

export class MetaModule extends InternalModule<MetaFunction, MetaEventType> {
    constructor(bridge: ApiBridge) {
        super(bridge, 'meta')
    }

    public isUpgraded(): Promise<boolean> {
        return this.callApiFunction(MetaFunction.isUpgraded, [])
    }

    public upgrade(openUi?: boolean): Promise<boolean> {
        return this.callApiFunction(MetaFunction.upgrade, [openUi])
    }

    public waitUntilInitialized(): Promise<void> {
        return this.callApiFunction(MetaFunction.waitUntilInitialized, [])
    }

    public deinitialize(): Promise<boolean> {
        return this.callApiFunction(MetaFunction.deinitialize, [])
    }
}