import {ApiBridge} from '../shared/internal/api-bridge';
import {MetaModule} from './module/meta-module';
import {GeneralLazyModule} from './module/general-lazy-module';
import {ConversationModule} from '../shared/internal/module/conversation-module';
import {AgentAvailabilityModule} from '../shared/internal/module/agent-availability-module';
import {GeneralModule} from "./module/general-module";
import {Configuration} from "../unblu-static-api";

export class InternalApi {
    public readonly  meta: MetaModule;
    public readonly  general: GeneralModule;
    public readonly  generalLazy: GeneralLazyModule;
    public readonly  conversation: ConversationModule;
    public readonly  agentAvailability: AgentAvailabilityModule;

    constructor(private readonly bridge: ApiBridge, public readonly configuration: Configuration) {
        this.meta = new MetaModule(bridge);
        this.general = new GeneralModule(bridge);
        this.generalLazy = new GeneralLazyModule(bridge);
        this.conversation = new ConversationModule(bridge);
        this.agentAvailability = new AgentAvailabilityModule(bridge);
    }

    public checkCompatibility(): void {
        this.bridge.checkCompatibility();
    }
}