export const enum IndividualUiState {
    /**
     * The individual ui is open.
     */
    OPEN = 'OPEN',
    /**
     * The individual ui is collapsed.
     */
    COLLAPSED = 'COLLAPSED',
    /**
     * The individual ui is popped out.
     */
    POPPED_OUT = 'POPPED_OUT'
}